//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getSocialPlatforms } from '@/api/home';
import { ERROR_OK } from '@/api/config';

export default {
  data() {
    return {
      data: {},
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      getSocialPlatforms().then((res) => {
        if (res.code === ERROR_OK) {
          this.data = res.data;
          this.$store.dispatch('user/setWhatsapp', res.data.whatsapp);
          this.$emit('whatsapp', res.data.whatsapp);
        }
      });
    },
  },

};
