/* eslint-disable */
import { getToken, setToken, removeToken } from '@/utils/auth';
import { saveToLocal, loadFromLocal } from '@/utils/cache';
import { login } from '@/api/login';
import { ERROR_OK } from '@/api/config';

const state = {
  token: getToken(),
  userInfo: loadFromLocal('userInfo', 'nfUser', {}),
  appInfo: loadFromLocal('userInfo', 'appInfo', {}),
  lang: '',
  product: '',
  whatsapp: '',

};
const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_USERINFO: (state, data) => {
    state.userInfo = data;
  },
  SET_APP_INFO: (state, data) => {
    state.appInfo = data;
  },
  SET_LANG: (state, lang) => {
    state.lang = lang;
  },
  SET_PRODUCT: (state, product) => {
    state.product = product;
  },
  SET_WHATSAPP: (state, data) => {
    state.whatsapp = data;
  },
};
const actions = {
  setWhatsapp({ commit }, data) {
    commit('SET_WHATSAPP', data);
  },
  login({ commit }, options) {
    return new Promise((resolve, reject) => {
      login(options).then(res => {
        if(res.code === ERROR_OK) {
          const { accessToken, nfUser, appInfo } = res.data;
          setToken(accessToken.token);
          saveToLocal('userInfo', 'nfUser', nfUser);
          saveToLocal('userInfo', 'appInfo', appInfo);
          commit('SET_TOKEN', accessToken.token);
          commit('SET_APP_INFO', appInfo);
          commit('SET_USERINFO', nfUser);
          resolve(res);
        }
      }).catch((error) => {
        reject(error);
      });
    });
  },
  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      removeToken();
      saveToLocal('userInfo', 'nfUser', '');
      resolve();
    })
  },
   // remove token
   resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '');
      saveToLocal('userInfo', 'nfUser', '');
      saveToLocal('userInfo', 'appInfo', '');
      removeToken();
      resolve();
    })
  },
  changeLang({ commit }, lang) {
    commit('SET_LANG', lang);
  },
  changeProduct({ commit }, product) {
    commit('SET_PRODUCT', product);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
