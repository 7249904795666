//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';
import { Icon } from 'vant';
import { mapState } from 'vuex';

Vue.use(Icon);
export default Vue.extend({
  data: () => ({
  }),
  computed: {
    ...mapState({
      token: (state) => state.user.token,
      userInfo: (state) => state.user.userInfo,
    }),
  },
  methods: {
    handleToMy() {
      this.$router.push({ path: '/user/my' });
    },
    handleLogout() {
      this.$store.dispatch('user/logout').then(() => {
        this.$router.replace({
          path: '/',
        });
        window.location.reload();
      });
    },
  },
});
