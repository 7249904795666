import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '@/layout/index.vue';
Vue.use(VueRouter);
const routes = [
    {
        path: '/redirect',
        component: Layout,
        children: [
            {
                path: '/redirect/:path*',
                component: () => import('@/views/redirect/index.vue'),
            },
            {
                path: '/notFound',
                component: () => import('@/views/exception/404.vue'),
            },
        ],
    },
    // {
    //   path: '/i18n',
    //   name: 'OurStory',
    //   component: () => import('@/views/ourStory/index.vue'),
    // },
    {
        path: '/',
        name: 'Layout',
        component: Layout,
        redirect: '/home',
        children: [
            {
                path: '/home',
                name: 'Home',
                component: () => import('@/views/ourStory/index.vue'),
            },
            {
                path: '/products',
                name: 'Products',
                component: () => import('@/views/product/index.vue'),
                meta: { title: 'Products' },
            },
            {
                path: '/login',
                name: 'Login',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/login/index.vue'),
                meta: { title: 'Login' },
            },
            {
                path: 'user/service',
                name: 'UserService',
                component: () => import('@/views/user/service/index.vue'),
                meta: { title: 'Subscription' },
            },
            {
                path: 'user/my',
                name: 'UserMy',
                component: () => import('@/views/user/my/index.vue'),
                meta: { title: 'Profile' },
            },
            // {
            //   path: 'user/redeem',
            //   name: 'UserRedeem',
            //   component: () => import('@/views/user/redeem/index.vue'),
            //   meta: { title: 'redeem' },
            // },
            {
                path: '/paysuccess',
                name: 'Paysuccess',
                component: () => import('@/views/feedback/paysuccess/index.vue'),
                meta: { title: 'Paysuccess' },
            },
        ],
    },
    {
        path: '*',
        redirect: '/notFound',
    },
];
const router = new VueRouter({
    // mode: 'history',
    scrollBehavior: () => ({ x: 0, y: 0 }),
    base: process.env.BASE_URL,
    routes,
});
export default router;
