import service from '@/utils/requset';

export function login(params) {
  return service({
    url: '/api/b/nf/user/logon',
    method: 'post',
    params,
    loading: true,
  });
}

export function sendEmailCode(params) {
  return service({
    url: '/api/b/nf/user/sendCode',
    method: 'post',
    params,
    loading: true,
  });
}
