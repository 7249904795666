export default {
    // home_page_header_btn1: 'Sign In',
    // login_select_index1: 'View Profile',
    // login_select_index2: 'HOME',
    // login_select_index3: 'PRODUCTS',
    // login_select_index4: 'ORDERS',
    // login_select_index5: 'SIGN OUT',
    // home_page_header_p1: 'Global 10000+ Live, unlimited movies and TV series.',
    // home_page_header_p2: 'Watch all in one account.',
    // home_page_header_p3: 'Ready to watch? Enter your email to get free test.',
    // home_page_header_input1: 'Email address',
    // home_page_header_label1: 'Email is required',
    // home_page_header_label2: 'Please enter a valid email address.',
    // home_page_header_btn2: 'Free Test',
    // home_page_body_p1: 'Global 1000+ Live.',
    // home_page_body_p2: 'World Cup, UEFA, LaLiga, Ligue 1, DAZN, SKY, Amzon, Bein...',
    // home_page_body_p3: 'Unlimited Movies.',
    // home_page_body_p4: '60000+ movies, the latest movies, real-time updates',
    // home_page_body_p5: 'Unlimited TV series.',
    // home_page_body_p6: 'Netflix, HBO, Apple TV... the latest series, real-time updates',
    // home_page_body_p7: 'Porn 18+',
    // home_page_body_p8: '18+ XXX, Hot, Kids lock',
    // home_page_footer_p1: 'Secured Payment with ',
    // home_page_footer_p2: 'Copyright © 2019 - 2022 toptvline Inc All Rights Reserved. ',
    // login: {
    //   login_page_input1: 'Email',
    //   login_page_label1: 'Email is required',
    //   login_page_label2: 'Email must be valid',
    //   login_page_input2: 'Verification Code',
    //   login_page_label3: 'Verification code is required',
    //   login_page_btn1: 'SEND CODE',
    //   login_page_btn2: 'COUNTDOWN',
    //   login_page_btn3: 'LOGIN WITH EMAIL AND CODE ',
    // },
    // home
    Users: 'Users',
    Orders: 'Orders',
    Accounts: 'Accounts',
    Sold: 'Sold',
    USD: 'USD',
    month: 'month',
    SALE: 'SALE',
    'Choose an available profile': 'Choose an available profile',
    'BUY NOW': 'BUY NOW',
    // login
    'Login to get the Real-time service.': 'Login to get the Real-time service.',
    'Verify code': 'Verify code',
    'Login without registration': 'Login without registration',
    'E-mail is required': 'E-mail is required',
    'E-mail must be valid': 'E-mail must be valid',
    'send code': 'send code',
    loading: 'loading',
    Confirm: 'Confirm',
    'Mail sent successfully': 'Mail sent successfully',
    'For this login to your email, please check your inbox. If you can\'t find it, please check your spam,if you have any questions,please free to contact us.': 'For this login to your email, please check your inbox. If you can\'t find it, please check your spam,if you have any questions,please free to contact us.',
    // Payment Successful
    'Payment Successful': 'Payment Successful',
    'Thank you ! We have received your payment.': 'Thank you ! We have received your payment.',
    'Your subscription is under processing.': 'Your subscription is under processing.',
    'Check my order': 'Check my order',
    // my
    'Nick Name': 'Nick Name',
    Valid: 'Valid',
    'Email address': 'Email address',
    // redeem
    'Enter your code below to get started': 'Enter your code below to get started',
    redeem: 'redeem',
    // service
    'Renew now': 'Renew now',
    Renew: 'Renew',
    Help: 'Help',
    Support: 'Support',
    'Please use profile No.': 'Please use profile No.',
    'Please only use your own profile. Don\'t change and delete another profile.': 'Please only use your own profile. Don\'t change and delete another profile.',
};
