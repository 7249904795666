const states = {
  axiosLoading: false, // 全局loading
  closeAxiosErrorLog: false, // 全局请求错误提示
};

const mutations = {
  ChANGE_LOADING: (state, axiosLoading) => {
    state.axiosLoading = axiosLoading;
  },
  CHANGE_CLOSE_AXIOS_ERROR_LOG: (state, flag) => {
    state.closeAxiosErrorLog = flag;
  },
};

const actions = {
  changeLoading({ commit }, loading) {
    commit('ChANGE_LOADING', loading);
  },
  changeCloseAxiosErrorLog({ commit }, flag) {
    commit('CHANGE_CLOSE_AXIOS_ERROR_LOG', flag);
  },
};

export default {
  namespaced: true,
  state: states,
  mutations,
  actions,
};
