//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Navbar, NfFooter } from './components';

export default {
  name: 'Layout',
  components: { Navbar, NfFooter },
  data() {
    return {
      whatsappLink: '',
    };
  },
  methods: {
    getWhatsappLink(url) {
      this.whatsappLink = url;
    },
  },
};
