const getters = {
    axiosLoading: (state) => state.config.axiosLoading,
    closeAxiosErrorLog: (state) => state.config.closeAxiosErrorLog,
    token: (state) => state.user.token,
    key: (state) => state.user.appInfo.key,
    lang: (state) => state.user.lang,
    product: (state) => state.user.product,
    pageRoute: (state) => state.page.pageRoute,
};
export default getters;
