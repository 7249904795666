import store from '@/store';
import { Toast } from 'vant';
import { debounce } from '@/utils/help';
import Vue from 'vue';

Vue.use(Toast);

let loading;
let needLoadingRequestCount = 0;

// eslint-disable-next-line
export function showFullScreenLoading() {
  if (needLoadingRequestCount === 0) {
    loading = Toast.loading({
      message: 'Loading...',
      forbidClick: true,
      duration: 0,
    });
  }
  needLoadingRequestCount += 1;
}

// eslint-disable-next-line
export function tryHideFullScreenLoading() {
  if (needLoadingRequestCount <= 0) return;
  needLoadingRequestCount -= 1;
  if (needLoadingRequestCount === 0) {
    store.dispatch('config/changeLoading', false);
    debounce(loading.close(), 300);
  }
}
