import router from '@/router';

import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { loadFromLocal } from '@/utils/cache';
import { loadLanguageAsync } from '@/lang/index';
import getPageTitle from './utils/getPageTitle';
import { getToken } from './utils/auth';
import store from './store/index';

NProgress.configure({ showSpinner: false });

const whiteList = ['/login', '/home', '/products', '/user/service', '/notFound'];

router.beforeEach(async (to, from, next) => {
  NProgress.start();

  // set page title
  document.title = getPageTitle(to.meta.title);

  // determine whether the user has logged in
  const hasToken = getToken();
  //  获取语言
  try {
    if (['Home', 'Login', 'Products'].includes(to.name)) {
      let routeName = to.name.toLowerCase();
      if (routeName === 'products') routeName = 'home';
      await loadLanguageAsync({
        routeName,
      });
    }
  } catch (error) {
    throw Error('get lang error');
  }
  if (hasToken) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({ path: '/' });
      NProgress.done();
    } else {
      const hasInfo = loadFromLocal('userInfo', 'nfUser', false);
      if (hasInfo) {
        next();
      } else {
        await store.dispatch('user/resetToken');
        next(`/home?redirect=${to.path}`);
        NProgress.done();
      }
    }
  } else {
    /* has no token */
    // eslint-disable-next-line no-lonely-if
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/home?redirect=${to.path}`);
      NProgress.done();
    }
  }
});

router.afterEach(() => {
  // finish progress bar
  NProgress.done();
});
