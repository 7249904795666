import service from '@/utils/requset';

export function getPortProduct() {
  return service({
    url: '/api/a/portal/product',
    method: 'post',
    loading: true,
  });
}

export function getPortanfcar(params) {
  return service({
    url: '/api/a/portal/nfcar',
    params,
    method: 'post',
    loading: true,
  });
}

export function getCountStatistic() {
  return service({
    url: '/api/a/portal/statistic',
    method: 'post',
  });
}

export function getPortSkuList(params) {
  return service({
    url: '/api/a/portal/skuList',
    method: 'post',
    params,
    loading: true,
  });
}

export function getOrderConfirm(data) {
  return service({
    url: '/api/b/nf/order/confirm',
    method: 'post',
    data,
    loading: true,
  });
}

export function createPaymentIntent(data) {
  return service({
    url: '/api/b/nf/order/stripe/create-payment-intent',
    method: 'post',
    data,
    loading: true,
  });
}

export function getTestLine(data) {
  return service({
    url: '/api/a/portal/testLine',
    method: 'post',
    data,
    loading: true,
  });
}

export function getFaqList() {
  return service({
    url: '/api/a/portal/faqList',
    method: 'post',
  });
}

export function getSocialPlatforms() {
  return service({
    url: '/api/a/portal/socialPlatforms',
    method: 'post',
  });
}
