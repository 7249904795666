/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Vue from 'vue';
import VueI18n from 'vue-i18n';
// English
import { getLangData } from '@/api/user';
import { saveToLocal, loadFromLocal } from '@/utils/cache';
import EN from './en/index';
import store from '../store/index';
Vue.use(VueI18n);
const language = loadFromLocal('userInfo', 'lang', '') || window.navigator.language.split('-')[0].toUpperCase();
const locale = ['EN', 'ES', 'FR', 'PT', 'PL', 'IT', 'DE', 'NL'].includes(language) ? language : 'EN';
saveToLocal('userInfo', 'lang', locale);
store.dispatch('user/changeLang', locale);
export const i18n = new VueI18n({
    locale,
    fallbackLocale: 'EN',
    messages: {
        EN,
    },
});
const languagesData = {};
export function loadedLanguages() {
    return [
        {
            text: 'English',
            value: 'EN',
        },
        {
            text: 'Español',
            value: 'ES',
        },
        {
            text: 'Français',
            value: 'FR',
        },
        {
            text: 'Português',
            value: 'PT',
        },
        {
            text: 'Polski',
            value: 'PL',
        },
        {
            text: 'Italiano',
            value: 'IT',
        },
        {
            text: 'Deutsch',
            value: 'DE',
        },
        {
            text: 'Nederlands',
            value: 'NL',
        },
    ];
}
export function loadLanguageAsync({ routeName }) {
    const lang = locale;
    return new Promise((resolve) => {
        if (languagesData[lang]?.includes(routeName)) {
            // 当前业务已经加载过语言配置
            i18n.locale = lang;
            resolve(lang);
        }
        else {
            getLangData({ pageId: routeName }).then((res) => {
                const { detail } = res.data;
                if (languagesData[lang]) {
                    // 当前语言已存在，则合并
                    i18n.mergeLocaleMessage(lang, detail);
                }
                else {
                    // 语言不存在，则添加
                    i18n.setLocaleMessage(lang, detail);
                    languagesData[lang] = [];
                }
                languagesData[lang].push(routeName);
                i18n.locale = lang;
                resolve(lang);
            }).catch(() => {
                resolve(lang);
            });
        }
    });
}
