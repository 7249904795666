import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { i18n } from './lang/index';
import vuetify from './plugins/vuetify';
import './styles/index.scss';
import './permission';
Vue.config.productionTip = false;
Vue.prototype.$t = (key, value) => i18n.t(key, value);
const vm = new Vue({
    router,
    store,
    vuetify,
    i18n,
    created() {
        this.$store.dispatch('user/changeLang', this.$i18n.locale);
    },
    watch: {
        $route(to, from) {
            this.$store.dispatch('page/changePageRoute', {
                to: {
                    name: to.name,
                    fullPath: to.fullPath,
                },
                from: {
                    name: from.name,
                    fullPath: from.fullPath,
                },
            });
        },
    },
    render: (h) => h(App),
}).$mount('#app');
export default vm;
