/* eslint-disable */

export function saveToLocal(id, key, value) {
  let NFConfig = window.localStorage._NFConfig_;
  if (!NFConfig) {
    NFConfig = {};
    NFConfig[id] = {};
  } else {
    try {
      NFConfig = JSON.parse(NFConfig);
    } catch (error) {
      NFConfig = {};
    }
    if (!NFConfig[id]) {
      NFConfig[id] = {};
    }
  }
  NFConfig[id][key] = value;
  window.localStorage._NFConfig_ = JSON.stringify(NFConfig);
}

export function loadFromLocal(id, key, def) {
  let NFConfig = window.localStorage._NFConfig_;
  if (!NFConfig) {
    return def;
  }
  try {
    NFConfig = JSON.parse(NFConfig)[id];
  } catch (error) {
    NFConfig = null;
  }
  if (!NFConfig) {
    return def;
  }
  const ret = NFConfig[key];
  if (ret !== undefined) {
    return ret;
  } else {
    return def;
  }
}
